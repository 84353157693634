body {
    font-family: 'Hind Madurai', sans-serif;;
}

$danger-msg-color: #ff4444;
$warning-msg-color: #ffbb33;
$success-msg-color: #00C851;
$info-msg-color: #33b5e5;

.danger-msg {
    color: #FFF;
    background-color: $danger-msg-color;
}

.warning-msg {
  color: #000;
  background-color: $warning-msg-color;  
}

.success-msg {
    color: #FFF;
    background-color: $success-msg-color;
}

.info-msg {
    color: #FFF;
    background-color: $info-msg-color;
}

///Xeditable css reset
.editable-buttons {
    display: block;
    margin-left: 0;
    vertical-align: top;
    font-size: 1.2rem;
    color: #FFF;
    cursor: pointer;
}

.editable-select {
    color: #000;
    margin-bottom: 10px;
    height: 2rem;
}

///Validate class error
.help-block {font-size:0.8rem; color: red;}

.tabs .indicator {
  background-color: #0277bd;
  display: none; }

.tabs .tab a.active {
  border-bottom: 2px solid #0277bd; }
